<script lang="ts">
  // @ts-nocheck
  import { onDestroy, onMount } from "svelte";

  import { Icon } from "@smui/common";
  import DataTable, { Body, Cell, Head, Row } from "@smui/data-table";
  import Button, { Label } from "@smui/button";
  import Dialog, { Title } from "@smui/dialog";
  import SelectMUI, { Option } from "@smui/select";
  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import MilestoneSpentHours from "../../../shared/MilestoneSpentHours/index.svelte";
  import LogsTable from "../../../shared/LogsTable/index.svelte";
  import Managers from "../../../shared/Managers/index.svelte";
  import Spinner from "../../../shared/Spinner/index.svelte";
  import Select from "svelte-select";
  import { notifier, NotificationDisplay } from "@beyonk/svelte-notifications";
  import { link } from "svelte-spa-router";
  import { projectsStore } from "stores/projectsStore";
  import { milestonesStore } from "stores/milestonesStore";
  import { authStore } from "stores/authStore";
  import { usersStore } from "stores/usersStore";
  import { modalPanelStore } from "stores/modalPanelStore";
  import type { UserInterface } from "interfaces/User.interface";
  import type { IProjectEditableParamsNormalize } from "interfaces/Project.interface";
  import { formatter } from "../../../../helpers/formatter";
  import Textfield from "@smui/textfield";
  import type { IModal } from "interfaces/Common.interface";
  import Invoices from "./InvoiceTabContent/index.svelte";
  export let props: { id: number };

  let availableBillingTypes = ["Time&Material", "Fixed Price"];

  const INFO_TAB = "Инфо";
  const CHANGES_TAB = "История изменений";
  const ACCOUNTING = "Счета";
  let tabs = [INFO_TAB, CHANGES_TAB, ACCOUNTING];
  let active: any;
  let changeStatusModal, changeFinishDateModal, modalHandler, changeTrackingPeriod, changeBillingTypeModal, changeTitleModal;
  let selectedStatusId;
  let selectedVehaId;
  let billingTypeId;

  let trackingStartValue = "";
  let trackingEndValue = "";
  let nameEdit = "";

  const { statuses, saveMilestone } =
    milestonesStore;
  let planRevenueValue = "", factRevenueValue = "", finishDateValue = "";

  const { users, getUsers } = usersStore;
  const { getDetailProject, detailProject } = projectsStore;
  // обновляем пользователей, получаем детальный проект и открываем панель при получении нового айди
  $: if (props) init(props);
  let isInitializing = false;
  let isMustUpdate = true;
  let planfact_value: number | string = "";

  async function init(props: { id: number }) {
    isInitializing = true;
    await Promise.all([getUsers(), getDetailProject(props.id)]);
    //@ts-ignore
    planfact_value = $detailProject.planfact_id || "";
    modalPanelStore.isOpen.set(true);
    updateModals($users);
    isInitializing = false;
  }

  type ModalId = "projectManagerId" | "accountManagerId";
  let managerModals: any;
  let planfact_modal: IModal | undefined;

  function updateModals(users: UserInterface[]) {
    managerModals = [
      {
        id: "projectManager",
        editableParam: "projectManagerId",
        modal: null,
        availableUsers: users.filter((user) => user.roles.includes("project_manager")),
        //@ts-ignore
        selectedUser: users.find((user) => user.id === $detailProject.projectManager?.id) || null
      },
      {
        id: "accountManager",
        editableParam: "accountManagerId",
        modal: null,
        availableUsers: users.filter((user) => user.roles.includes("account_manager")),
        //@ts-ignore
        selectedUser: users.find((user) => user.id === $detailProject.accountManager?.id) || null
      }
    ];
  }

  let buttonDisabler = false;

  const isEditable = authStore.hasPermission(["projects_update", "projects_full_access"]);

  function managerEditHandler(e: CustomEvent) {
    const manager = e.detail;
    const selectedModal = managerModals.find((modal) => modal.id === manager);
    selectedModal.modal.open();
  }

  function selectHandler(e: CustomEvent, modalId: ModalId) {
    const openedModal = managerModals.find((modal) => modal.id === modalId);
    openedModal.selectedUser = e.detail;
  }

  function handleChangePlanfact(e: Event) {
    planfact_value = (e.target as HTMLInputElement).value;
  }

  function clearHandler(modalId: ModalId) {
    const openedModal = managerModals.find((modal) => modal.id === modalId);
    openedModal.selectedUser = null;
  }

  async function toggleIsArchived() {
    //@ts-ignore
    await saveProject({ isArchived: !$detailProject.isArchived });
    await Promise.all([milestonesStore.getLiteMilestones(), projectsStore.getProjects()]);
  }

  async function saveProject(changedParam: IProjectEditableParamsNormalize) {
    //@ts-ignore
    const { message, success } = await projectsStore.saveProject(changedParam, $detailProject.id);
    if (success) notifier.success(message);
    else notifier.danger(message);
    //@ts-ignore
    await getDetailProject($detailProject.id);

    return success;
  }

  async function saveFromModalHandler(modalId: ModalId) {
    buttonDisabler = true;
    const openedModal = managerModals.find((modal) => modal.id === modalId);
    if (!openedModal.selectedUser) {
      notifier.danger("Выберите пользователя");
      buttonDisabler = false;
      return;
    }
    const changedParam = {};
    changedParam[openedModal.editableParam] = openedModal.selectedUser?.id || null;
    await saveProject(changedParam);
    buttonDisabler = false;
    openedModal.modal.close();
  }

  async function savePlanfact() {
    buttonDisabler = true;

    const success = await saveProject({ planfact_id: +planfact_value });

    if (success) {
      //@ts-ignore
      planfact_modal?.close();
    }

    buttonDisabler = false;
  }

  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => {
    if (option) return option.name;
  };
  onMount(() => {
    isMustUpdate = true;
  });
  onDestroy(() => {
    if (isMustUpdate) $detailProject = null;
  });
</script>

<NotificationDisplay />
{#if isInitializing}
  <div class="_with-air">
    <Spinner />
  </div>
{:else if $detailProject}
  <div class="projectTable">
    <div class="projectTable__header">
      <h3 class="projectTable__projectName">{$detailProject.name}</h3>
      <div
        class:projectTable__archive--editable={isEditable}
        class="projectTable__archive"
        on:click={toggleIsArchived}
      >
        {#if $detailProject.isArchived}
          <Icon class="material-icons">sync</Icon>
          <span>Вернуть из архива</span>
        {:else}
          <Icon class="material-icons">inventory</Icon>
          <span>Спрятать в архив</span>
        {/if}
      </div>
    </div>

    <TabBar bind:active let:tab {tabs}>
      <Tab minWidth {tab}>
        <Label>{tab}</Label>
      </Tab>
    </TabBar>

    {#if active === INFO_TAB}
      <div class="projectTable__info">
        <div class="projectTable__managerWrapper">
          <Managers
            accountManager={$detailProject.accountManager}
            projectManager={$detailProject.projectManager}
            {isEditable}
            on:edit={managerEditHandler}
          />
        </div>

        <div on:click={() => planfact_modal?.open()} class="projectTable__planfact grayOpenSans">
          <Icon class="material-icons" style="font-size: 14px; margin-right: 2px">content_copy</Icon
          >
          <span class="projectTable__planfact-text"
            >Планфакт: {$detailProject.planfact_id || "Не привязан"}</span
          >
        </div>

        {#if $detailProject.planProductionProfitability && $detailProject.factProductionProfitability}
          <div class="projectTable__profitWrapper">
            <Icon class="material-icons" style={`color:gray;font-size: 16px; margin-right: 8px`}>
              monetization_on
            </Icon>
            <div class="projectTable__profit">
              <span>
                Рентабельность пр-ва(план): {$detailProject.planProductionProfitability} %
              </span>
              <span>
                Рентабельность пр-ва(факт): {$detailProject.factProductionProfitability} %
              </span>
            </div>
          </div>
        {/if}

        {#if $detailProject.planTotalProfitability && $detailProject.factTotalProfitability}
          <div class="projectTable__profitWrapper">
            <Icon class="material-icons" style={`color:gray;font-size: 16px; margin-right: 8px`}>
              monetization_on
            </Icon>
            <div class="projectTable__profit">
              <span>
                Общая рентабельность(план): {$detailProject.planTotalProfitability} %
              </span>
              <span>
                Общая рентабельность(факт): {$detailProject.factTotalProfitability} %
              </span>
            </div>
          </div>
        {/if}
      </div>

      {#if $detailProject?.milestones?.length}
        <DataTable table$aria-label="Info">
          <Head>
            <Row>
              <Cell width="200">Имя</Cell>
              <Cell width="200">Статус</Cell>
              <Cell width="200">Трудозатраты</Cell>
              <Cell width="200">Дата завершения</Cell>
              <Cell width="200">Учет времени</Cell>
              <Cell width="200">Выручка</Cell>
              <Cell width="200">Биллинг</Cell>
            </Row>
          </Head>
          <Body>
            {#if $detailProject}
            {#each $detailProject.milestones as { id, name, status, planRevenue, factRevenue, spentHours, billingType, maxBillingHours, is_guarantee, is_archived, finish_date, tracking_start, tracking_end } (id)}
              <Row class={is_archived ? 'project-table-row_disabled' : undefined}>
                <Cell>
                  <Button class="milestoneTable__editBtn" style="margin-right: 10px;" on:click={() => {
                    selectedVehaId = id;
                    nameEdit = name;
                    changeTitleModal.open();
                  }}>
                    <Icon class="material-icons" style="margin: 0;">mode_edit</Icon>
                  </Button>
                    <a class="name-link" href={`/projects/milestonesTab/${id}`} use:link>{name}</a>
                    {#if is_guarantee}
                      <sup>Гарантия</sup>
                    {/if}
                </Cell>
                <Cell>
                  <Button
                    class="milestoneTable__editBtn"
                    disabled={!isEditable || is_archived}
                    on:click={() => {
                      selectedStatusId = status.id
                      selectedVehaId = id;
                      changeStatusModal.open();
                      }}
                    variant="outlined"
                  >
                    {#if isEditable}
                      <Icon class="material-icons">mode_edit</Icon>
                    {/if}
                    {status.name}
                  </Button>
                </Cell>
                <Cell>
                  <MilestoneSpentHours
                    {spentHours}
                    {billingType}
                    totalProjectHours={$detailProject.spentHours.totalHours}
                    {maxBillingHours}
                  />
                </Cell>
                <Cell>
                  <span
                class="name-link"
                class:milestoneTable__editableText--disabled={!isEditable}
                on:click={() => {
                finishDateValue = finish_date || "";
                selectedVehaId = id;
                changeFinishDateModal.open();
                }}
              >
                {#if isEditable}
                  <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                {/if}
                {formatter.date(finish_date)}
              </span>
                </Cell>
                <Cell>
                  <div class="milestoneTable__textBlock">
                    Начало:
                      <span
                        class:name-link={isEditable}
                        on:click={() => {
                          trackingStartValue = tracking_start;
                          trackingEndValue = tracking_end;
                          selectedVehaId = id;
                          changeTrackingPeriod.open();
                        }}
                      >
                        {#if isEditable}
                          <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                        {/if}
                        {formatter.date(tracking_start)}
                      </span>
                      <div>
                        Конец:
                        <span
                      class:name-link={isEditable}
                      on:click={() => {
                        trackingStartValue = tracking_start;
                        trackingEndValue = tracking_end;
                        selectedVehaId = id;
                        changeTrackingPeriod.open();
                      }}
                    >
                      {#if isEditable}
                        <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                      {/if}
      
                      {formatter.date(tracking_end)}
                    </span>
                      </div>
                  </div>
                </Cell>
                <Cell>
                  <div class="milestoneTable__textBlock">
                    <div>
                      План:
                      <span
                        class:name-link={isEditable}
                        on:click={() => {
                          if (isEditable) {
                            selectedVehaId = id;
                            planRevenueValue = planRevenue;
                            factRevenueValue = factRevenue;
                            modalHandler.open();
                          }
                        }}
                      >
                        {#if isEditable}
                          <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                        {/if}
        
                        {planRevenue
                          ? formatter.money(planRevenue)
                          : "не задано"}
                      </span>
                    </div>
                    <div>
                      Факт:
                      <span
                        class="name-link"
                        class:milestoneTable__editableText--disabled={!isEditable}
                        on:click={() => {
                          if (isEditable) {
                            selectedVehaId = id;
                            planRevenueValue = planRevenue;
                            factRevenueValue = factRevenue;
                            modalHandler.open();
                          }
                        }}
                      >
                        {#if isEditable}
                          <Icon class="material-icons" style="font-size: 10px">mode_edit</Icon>
                        {/if}
                        {factRevenue
                          ? formatter.money(factRevenue)
                          : "не задано"}
                      </span>
                    </div>
                  </div>
                </Cell>
                <Cell>
                  <Button
        class="milestoneTable__editBtn"
        disabled={!isEditable}
        on:click={() => {
          billingTypeId = billingType;
          selectedVehaId = id;
          changeBillingTypeModal.open();
        }}
        variant="outlined"
      >
        {#if isEditable}
          <Icon class="material-icons">mode_edit</Icon>
        {/if}
        {billingType}
      </Button>
                </Cell>
              </Row>
            {/each}
            {/if}
          </Body>
        </DataTable>
      {/if}
    {/if}

    {#if active === CHANGES_TAB}
      <div class="projectTable__changesTable">
        <LogsTable params={{ type: "project", id: $detailProject.id }} />
      </div>
    {/if}

    {#if active === ACCOUNTING}
      <div class="milestoneTable__tabContent">
        <Invoices isAbleToAdd={false} />
      </div>
    {/if}
  </div>
{/if}

<div class="projectTable__modal">
  <Dialog bind:this={planfact_modal}>
    <Title>Введите номер планфакта</Title>
    <div class="projectTable__modalBody">
      <div class="projectTable__field">
        <Textfield
          bind:value={planfact_value}
          type="number"
          on:input={(e) => handleChangePlanfact(e)}
        />
      </div>
    </div>

    <div class="projectTable__modalFooter">
      <Button disabled={buttonDisabler} on:click={savePlanfact}>Сохранить</Button>
    </div>
  </Dialog>
</div>

<div class="projectTable__modal">
  <Dialog bind:this={changeTitleModal}>
    <Title>Редактировать имя</Title>
    <div class="milestoneTable__contentWrapper">
      <Textfield label="Имя" bind:value={nameEdit} />
    </div>
    <footer class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={async () => {
        changeTitleModal.close();

        await saveMilestone({
          name: nameEdit,
          sort: 5000,
        }, selectedVehaId);

        await init(props);
      }}>Сохранить</Button>
    </footer>
  </Dialog>
  {#if managerModals}
    {#each managerModals as { id, modal, availableUsers, selectedUser }}
      <Dialog bind:this={modal}>
        <Title>Выберите менеджера</Title>
        <div class="projectTable__modalBody">
          <Select
            items={availableUsers}
            optionIdentifier="id"
            selectedValue={selectedUser}
            {getSelectionLabel}
            {getOptionLabel}
            on:select={(e) => selectHandler(e, id)}
            on:clear={() => clearHandler(id)}
          />
        </div>

        <div class="projectTable__modalFooter">
          <Button disabled={buttonDisabler} on:click={() => saveFromModalHandler(id)}>
            Сохранить
          </Button>
        </div>
      </Dialog>
    {/each}
  {/if}
  <Dialog bind:this={changeStatusModal}>
    <Title>Выберите статус</Title>
    <div class="milestoneTable__contentWrapper">
      <SelectMUI
        value={selectedStatusId?.toString()}
        label="Статус"
      >
      {#if $statuses}
        {#each $statuses as { id, name }}
          <Option on:click={() => {
            selectedStatusId = id;
          }} value={id.toString()}>{name}</Option>
        {/each}
      {/if}
      </SelectMUI>
    </div>
    <footer class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={async () => {
        changeStatusModal.close();

        await saveMilestone({
          statusId: selectedStatusId,
          sort: 5000,
        }, selectedVehaId);

        await init(props);
      }}>Сохранить</Button>
    </footer>
  </Dialog>
  <Dialog bind:this={changeFinishDateModal}>
    <Title>Выберите дату завершения</Title>
    <div class="milestoneTable__contentWrapper">
      <Textfield
        type="date"
        bind:value={finishDateValue}
        on:input={(e) => {
          finishDateValue = e.target.value;
        }}
      />
    </div>
    <h2>
      <div />
    </h2>
    <div class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={async () => {
        changeFinishDateModal.close();

        await saveMilestone({
          finish_date: finishDateValue,
          sort: 5000,
        }, selectedVehaId);

        await init(props);
      }}>Сохранить</Button>
    </div>
  </Dialog>
  <Dialog bind:this={modalHandler}>
    <Title>Выручка</Title>
    <div class="milestoneTable__contentWrapper fas">
      <div>
        <div>План:</div>
        <Textfield type="number" bind:value={planRevenueValue} input$min="0" />
      </div>
      <div>
        <div>Факт:</div>
        <Textfield type="number" bind:value={factRevenueValue} input$min="0" />
      </div>
    </div>
    <footer class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={async () => {
        modalHandler.close();

                await saveMilestone({
                  planRevenue: planRevenueValue,
                  factRevenue: factRevenueValue,
                  sort: 5000,
                }, selectedVehaId);

                await init(props);
      }}>Сохранить</Button>
    </footer>
  </Dialog>

  <Dialog bind:this={changeTrackingPeriod}>
    <Title>Выберите даты учета времени</Title>
    <div class="milestoneTable__contentWrapper milestoneTable__contentWrapper_ads fas">
      <div>
        <div>Начало</div>
        <Textfield
        type="date"
        bind:value={trackingStartValue}
        on:input={(e) => {
          trackingStartValue = e.target.value;
        }}
        />
      </div>
      <div>
        <div>Конец</div>
        <Textfield
          type="date"
          bind:value={trackingEndValue}
          on:input={(e) => {
            trackingEndValue = e.target.value;
          }}
        />
      </div>
    </div>
    <h2>
      <div />
    </h2>
    <div class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={async () => {
        changeTrackingPeriod.close();

        await saveMilestone({
          tracking_start: trackingStartValue,
          tracking_end: trackingEndValue,
          sort: 5000,
        }, selectedVehaId);

        await init(props);
      }}>Сохранить</Button>
    </div>
  </Dialog>
  <Dialog bind:this={changeBillingTypeModal}>
    <Title>Выберите тип биллинга</Title>
    <div class="milestoneTable__contentWrapper">
      <SelectMUI
        value={billingTypeId}
        label="Тип биллинга"
      >
      {#each availableBillingTypes as billingType}
      <Option value={billingType} on:click={() => {
        billingTypeId = billingType
      }}>{billingType}</Option>
    {/each}
      </SelectMUI>
    </div>
    <footer class="milestoneTable__dialogFooter">
      <Button disabled={buttonDisabler} on:click={async () => {
        changeBillingTypeModal.close();

        await saveMilestone({
          billingType: billingTypeId,
          sort: 5000,
        }, selectedVehaId);

        await init(props);
      }}>Сохранить</Button>
    </footer>
  </Dialog>
</div>


<style lang="scss">
  
  .milestoneTable__dialogFooter {
    padding: 0 20px 20px;
  }
  .name-link {
    cursor: pointer;
    color: var(--violet);
    text-decoration: none;
    border-bottom: 1px solid currentColor;

    &:hover {
      color: black;
    }
  }

  .fas {
    display: flex;
    gap: 20px;
  }

  .milestoneTable__contentWrapper {
    padding: 20px;
  }

  :global(.project-table-row_disabled) {
    opacity: 0.5;
  }
  .projectTable {
    min-width: 700px;

    &__header {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
    }

    &__archive {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;

      &--editable {
        &:hover {
          cursor: pointer;

          span {
            text-decoration: underline;
          }
        }
      }
    }

    &__info {
      display: flex;
      margin: 26px 0;
    }

    &__planfact {
      font-size: 11px;
      line-height: 18px;
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      margin-left: 40px;

      &-text {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &__profitWrapper {
      display: flex;
      margin-left: 140px;
    }

    &__changesTable {
      margin: 26px 0;
    }

    &__profit {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      span {
        display: block;
      }
    }

    &__modal {
      :global(.mdc-dialog__surface) {
        overflow-y: inherit;
      }
    }

    &__field {
      padding: 0px 24px;
    }

    &__modalFooter {
      padding: 20px 24px;
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
