<script lang="typescript">
  import Dialog, { Title } from "@smui/dialog";
  import Button from "@smui/button";
  import { companiesStore } from "stores/companiesStore";
  import Spinner from "../../../../shared/Spinner/index.svelte";
  import CompanyFormField from "../CompanyFormField.svelte";
  import { mapCompanyRequestInformation } from "helpers/helpers";
  import type { IModal } from "interfaces/Common.interface";

  export let companyModal: IModal | undefined;
  export let companyModalClose: () => void;
  export let inn: string;
  export let isOurCompany: boolean;
  export let selectedCompanyType: number;
  export let selectedVatType: number | null;
  export let errors: any;
  export let isEditMode: boolean;
  export let editingCompanyId: number | null;
  export let editInn: string | number;

  const { companyBySuggestion, isLoading, editCompany, addCompany } = companiesStore;

  const saveCompany = async () => {
    const res = isEditMode
      ? await editCompany(
          //@ts-ignore
          mapCompanyRequestInformation($companyBySuggestion, selectedCompanyType, selectedVatType, isOurCompany),
          editingCompanyId
        )
      : await addCompany(
          //@ts-ignore
          mapCompanyRequestInformation($companyBySuggestion, selectedCompanyType, selectedVatType, isOurCompany)
        );

    if (res.success) {
      companyModalClose();
    } else {
      errors = res.message;
    }
  };
</script>

<div class="companies__modal">
  <Dialog bind:this={companyModal} on:MDCDialog:closed={companyModalClose}>
    <Title id="simple-title">Контрагент - {editInn || inn}</Title>
    <div class="companies-modal__fields-wrap">
      {#if $isLoading}
        <div class="spinner-container">
          <Spinner />
        </div>
      {:else}
        {#each Object.keys($companyBySuggestion) as key}
          <CompanyFormField
            invalidValue={errors[key]}
            bind:value={$companyBySuggestion[key].value}
            label={$companyBySuggestion[key].label}
            type={$companyBySuggestion[key].type}
            validate={$companyBySuggestion[key].validate}
            bind:selectedCompanyType={selectedCompanyType}
            bind:selectedVatType={selectedVatType}
            bind:checked={isOurCompany}
          />
        {/each}
      {/if}
    </div>

    <footer class="companies-modal__buttons">
      <Button on:click={saveCompany}>Сохранить</Button>
    </footer>
  </Dialog>
</div>

<style lang="scss">
  .companies-modal {
    &__buttons {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 15px;
    }

    &__fields-wrap {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }
  }
</style>
