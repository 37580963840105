import { apiDeleteCompany } from "api/methods/companies/apiDeleteCompany";
import { apiGetCompanies } from "../api/methods/companies/apiGetCompanies";
import { apiGetCompanyBySuggestions } from "../api/methods/companies/apiGetCompanyBySuggestions";
import { apiPostCompany } from "../api/methods/companies/apiPostCompany";
import { apiPutCompany } from "api/methods/companies/apiPutCompany";
import { mapCompanyInformation } from "helpers/helpers";
import { notifier } from "@beyonk/svelte-notifications";
import { writable } from "svelte/store";
const companiesCells = [
    "Название",
    "ИНН",
    "Наименование юрлица",
    "КПП",
    "БИК банка",
    "Название банка",
    "Корр. счёт банка",
    "Счет организации в банке",
    "Адрес организации",
    "Тип контрагента",
    "Применяемый НДС",
    "Наше юр.лицо?",
    ""
];
const companies = writable({ total: 0, companies: [] });
const isLoading = writable(false);
const error = writable(null);
const companyBySuggestion = writable({});
const getCompanyBySuggestion = async (inn) => {
    isLoading.set(true);
    const result = await apiGetCompanyBySuggestions(inn);
    if (result.data) {
        const company = result.data.length ? result.data[0] : {};
        if (result.success)
            companyBySuggestion.set(mapCompanyInformation(company, inn));
        else
            error.set(result.message);
    }
    isLoading.set(false);
};
const getCompanies = async () => {
    isLoading.set(true);
    const result = await apiGetCompanies();
    if (result.success) {
        if (result.data) {
            if (result.success)
                companies.set(result.data);
            else
                error.set(result.message);
        }
    }
    else {
        notifier.danger(result.message, 2000);
    }
    isLoading.set(false);
};
const editCompany = async (company, id) => {
    const res = await apiPutCompany(company, id);
    if (res.success)
        getCompanies();
    return res;
};
const deleteCompany = async (id) => {
    const res = await apiDeleteCompany(id);
    if (res.success)
        getCompanies();
    return res;
};
const addCompany = async (company) => {
    const res = await apiPostCompany(company);
    if (res.success)
        getCompanies();
    return res;
};
export const companiesStore = {
    getCompanies,
    getCompanyBySuggestion,
    addCompany,
    companyBySuggestion,
    companiesCells,
    companies,
    isLoading,
    editCompany,
    deleteCompany
};
