import { notifier } from "@beyonk/svelte-notifications";
import { apiGetInvoices } from "api/methods/invoices/apiGetInvoices";
import { writable, get, derived } from "svelte/store";
import { apiPostInvoice } from "api/methods/invoices/apiPostInvoice";
import { apiPutInvoice } from "api/methods/invoices/apiPutInvoice";
import { apiGetInvoice } from "api/methods/invoices/apiGetInvoice";
import { apiGetInvoiceStatuses } from "api/methods/invoices/apiGetInvoiceStatuses";
import { apiDeleteInvoice } from "api/methods/invoices/apiDeleteInvoice";
import { apiGetInvoicePositionUnits } from "api/methods/invoices/apiGetInvoicePositionUnits";
import { DEFAULT_LENGTH_LIST, DEFAULT_STATUSES } from "constants/constants";
import { getVatTypeSelectOption } from "./utils/vatTypeUtils";
import { toSelectItem } from "../helpers/helpers";
const posErrors = {
    name: '',
    price: '',
    quantity: '',
    unit: '',
};
const defaultFormFields = [
    { label: "Название счёта", required: true, value: "", type: "text", name: "name", slug: "name", error: "", disabled: false, },
    { label: "Номер счёта", required: true, value: "", type: "text", error: "", name: "number", slug: "number", disabled: true },
    {
        label: "Компания клиента",
        value: null,
        required: true,
        type: "select",
        error: "",
        name: "company_id",
        items: [],
        slug: "company",
        disabled: false,
    },
    {
        label: "Наше юр. лицо",
        value: null,
        required: true,
        type: "select",
        error: "",
        name: "self_company_id",
        items: [],
        slug: "self_company",
        disabled: false,
    },
    { label: "Статус", value: null, required: true, type: "select", error: "", name: "status_id", slug: "status", disabled: false, items: [] },
    { label: "Дата выставления", required: true, value: "", type: "date", name: "date", slug: "date", disabled: false, error: "" },
    { label: "Комментарий", value: "", type: "text", name: "comment", slug: "comment", disabled: false, required: false, error: "" },
    {
        disabled: true,
        label: "ПланФакт",
        required: false,
        value: "",
        hidden: true,
        type: "text",
        error: "",
        name: "planfact_payment_id",
        slug: "planfact_payment_id",
    },
    { label: "Применяемый НДС", value: null, required: false, type: "select", error: "", items: [], name: "vat_type_id", slug: "vat_type", disabled: false },
    { label: "Общая сумма", value: "", required: false, type: "number", error: "", name: "total_sum", slug: "total_sum", hidden: true, disabled: true },
    { label: "Веха", value: null, required: true, type: "select", error: "", items: [], name: "milestone_id", slug: "milestone", disabled: false, },
    { label: "Дата оплаты", value: "", required: false, type: "date", error: "", name: "payment_date", slug: "payment_date", disabled: false, },
];
const setDefaultFields = (fields) => {
    return fields.map((item, i) => {
        return Object.assign(Object.assign({}, item), { disabled: defaultFormFields[i].disabled, error: '', value: fields[i].defaultValue || defaultFormFields[i].value });
    });
};
const invoices = writable({ total: 0, invoices: [] });
const isLoading = writable(true);
const editId = writable(null);
const error = writable(null);
const invoice = writable(JSON.parse(JSON.stringify(defaultFormFields)));
const filters = writable({ search: '', status_id: '', limit: DEFAULT_LENGTH_LIST, offset: 0 });
const statuses = writable([]);
const positionUnits = writable([]);
//@ts-ignore
const emptyPosition = derived(positionUnits, $positionUnits => ({
    name: '',
    price: 0,
    quantity: 0,
    unit: $positionUnits.find((item) => item.name === "Штука") || $positionUnits[0],
    errors: JSON.parse(JSON.stringify(posErrors))
}));
const currentPositions = writable([]);
const getInvoices = async () => {
    isLoading.set(true);
    const result = await apiGetInvoices(get(filters));
    if (result.success) {
        result.data && invoices.set(result.data);
    }
    else {
        result.message && error.set(result.message);
    }
    isLoading.set(false);
};
const getPositionUnits = async () => {
    const result = await apiGetInvoicePositionUnits();
    if (result.success) {
        result.data && positionUnits.set(result.data);
    }
    return result;
};
const createInvoice = async (data) => {
    const createResult = await apiPostInvoice(data);
    if (createResult.success) {
        getInvoices();
    }
    return createResult;
};
const getInvoice = async (id) => {
    const result = await apiGetInvoice(id);
    const { data, success } = result;
    if (success) {
        if (data) {
            data.positions && currentPositions.set(data.positions.map(item => (Object.assign(Object.assign({}, item), { errors: JSON.parse(JSON.stringify(posErrors)) }))));
            const getInvoice = get(invoice);
            const formatted = getInvoice.map((item) => {
                var _a, _b;
                const key = item.slug;
                let extra;
                let value = data[key] || '';
                if (key === 'vat_type') {
                    value = toSelectItem(getVatTypeSelectOption(data[key]), 'label', 'value', true);
                }
                if (key === 'total_sum') {
                    value = (_a = data[key]) === null || _a === void 0 ? void 0 : _a.toFixed(2);
                    extra = { vat: data.vat, vatPercentageRate: ((_b = data.vat_type) === null || _b === void 0 ? void 0 : _b.percentage_rate) || null };
                }
                return Object.assign(Object.assign({}, item), { value: value, extra: extra });
            });
            invoice.set(formatted);
        }
    }
    return result;
};
const editInvoice = async (data, id) => {
    const editResult = await apiPutInvoice(data, id);
    if (editResult.success) {
        getInvoices();
    }
    return editResult;
};
const getInvoiceStatuses = async () => {
    const savedStatuses = localStorage.getItem('statuses');
    isLoading.set(true);
    const result = await apiGetInvoiceStatuses();
    if (result.success) {
        result.data && statuses.set(result.data);
        if (result.data) {
            filters.update(data => (Object.assign(Object.assign({}, data), { 
                //@ts-ignore
                status_id: result.data
                    .filter(item => (savedStatuses || DEFAULT_STATUSES).includes(item.name))
                    .map(item => item.id).join(',') })));
        }
    }
    else {
        notifier.danger(result.message, 2000);
    }
    isLoading.set(false);
};
const deleteInvoice = async (id) => {
    const result = await apiDeleteInvoice(id);
    if (result.success)
        getInvoices();
    return result;
};
export const invoicesStore = {
    getInvoices,
    createInvoice,
    editInvoice,
    getInvoice,
    getInvoiceStatuses,
    deleteInvoice,
    getPositionUnits,
    setDefaultFields,
    statuses,
    invoices,
    invoice,
    filters,
    positionUnits,
    isLoading,
    defaultFormFields,
    editId,
    currentPositions,
    emptyPosition,
};
