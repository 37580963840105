import { checkBIK, checkCorrespondentAccount, checkINN, checkKPP } from "helpers/bills-validate";
export const formatDate = (date) => {
    const newDate = new Date(date);
    const options = {
        hour: "numeric",
        minute: "numeric",
        month: "long",
        day: "numeric",
        year: "numeric"
    };
    return new Intl.DateTimeFormat("ru", options).format(newDate);
};
export function debounce(func, time) {
    let timer;
    return function (args) {
        clearTimeout(timer);
        timer = setTimeout(() => { func(args); }, time);
    };
}
;
//@ts-ignore
export const toSelectItems = (array, label, value, isCustom) => (array.map(item => toSelectItem(item, label, value, isCustom)));
export const toSelectItem = (item, label, value, isCustom) => ({ [isCustom ? "name" : "label"]: item[label], [isCustom ? "id" : "value"]: item[value] });
export const mapReadMessages = (id, messages) => {
    return messages
        .filter((message) => { var _a; return message.is_read === false && id !== ((_a = message === null || message === void 0 ? void 0 : message.user) === null || _a === void 0 ? void 0 : _a.id); })
        .map((message) => message.id);
};
export const mapPaymentInformation = (paymentInformation) => {
    var _a, _b, _c, _d, _e;
    return {
        adjustment: (paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.adjustment) || "",
        adjustment_comment: paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.adjustment_comment,
        calculated_sum: paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.calculated_sum,
        total_sum: paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.total_sum,
        noncash_sum: ((_a = paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.noncash) === null || _a === void 0 ? void 0 : _a.sum) || 0,
        cash_sum: ((_b = paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.cash) === null || _b === void 0 ? void 0 : _b.sum) || 0,
        is_approved: paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.is_approved,
        approved_by: (_c = paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.approved_by) === null || _c === void 0 ? void 0 : _c.name,
        noncash_is_paid: (_d = paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.noncash) === null || _d === void 0 ? void 0 : _d.is_paid,
        cash_is_paid: (_e = paymentInformation === null || paymentInformation === void 0 ? void 0 : paymentInformation.cash) === null || _e === void 0 ? void 0 : _e.is_paid
    };
};
export const mapCompanyInformation = (companyBySuggestion, inn, company, vatTypes) => {
    var _a, _b, _c, _d;
    return {
        name: {
            value: (company === null || company === void 0 ? void 0 : company.name) || "",
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "Название"
        },
        req_inn: {
            value: inn || 0,
            type: "text",
            validate(value) {
                return !checkINN(value);
            },
            label: "ИНН"
        },
        req_name: {
            value: (company === null || company === void 0 ? void 0 : company.details.name) || ((_a = companyBySuggestion === null || companyBySuggestion === void 0 ? void 0 : companyBySuggestion.name) === null || _a === void 0 ? void 0 : _a.full) || "",
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "Наименование юридического лица "
        },
        req_kpp: {
            value: (company === null || company === void 0 ? void 0 : company.details.kpp) || (companyBySuggestion === null || companyBySuggestion === void 0 ? void 0 : companyBySuggestion.kpp) || "",
            type: "text",
            validate(value) {
                return !checkKPP(value);
            },
            label: "КПП организации"
        },
        req_bank_bik: {
            value: (company === null || company === void 0 ? void 0 : company.details.bank.bic) || '',
            type: "text",
            validate(value) {
                return !checkBIK(value);
            },
            label: "БИК банка"
        },
        req_bank_name: {
            value: (company === null || company === void 0 ? void 0 : company.details.bank.name) || "",
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "Название банка"
        },
        req_bank_ks: {
            value: (company === null || company === void 0 ? void 0 : company.details.bank['correspondent account']) || "",
            type: "text",
            validate(value, bik) {
                return !checkCorrespondentAccount(value, bik);
            },
            label: "К/счет банка"
        },
        req_bank_ls: {
            value: (company === null || company === void 0 ? void 0 : company.details.payment_account) || '',
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "Счет организации в банке"
        },
        req_address: {
            value: (company === null || company === void 0 ? void 0 : company.details.address) || (companyBySuggestion === null || companyBySuggestion === void 0 ? void 0 : companyBySuggestion.address) || "",
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "Адрес организации"
        },
        req_boss_post: {
            value: (company === null || company === void 0 ? void 0 : company.details.boss.position) || ((_b = companyBySuggestion === null || companyBySuggestion === void 0 ? void 0 : companyBySuggestion.boss) === null || _b === void 0 ? void 0 : _b.post) || "",
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "Должность руководителя "
        },
        req_boss_name: {
            value: (company === null || company === void 0 ? void 0 : company.details.boss.name) || ((_c = companyBySuggestion === null || companyBySuggestion === void 0 ? void 0 : companyBySuggestion.boss) === null || _c === void 0 ? void 0 : _c.name) || "",
            type: "text",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "ФИО руководителя"
        },
        planfact_id: {
            value: (company === null || company === void 0 ? void 0 : company.planfact_id) || "",
            type: "number",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "ИД контрагента в Планфакте"
        },
        planfact_account_id: {
            value: (company === null || company === void 0 ? void 0 : company.planfact_account_id) || "",
            type: "number",
            validate(value) {
                return !(value === null || value === void 0 ? void 0 : value.length);
            },
            label: "ИД счета контрагента в Планфакте"
        },
        type_id: {
            value: (company === null || company === void 0 ? void 0 : company.type.id) || 1,
            type: "select",
            label: "Тип контаргента"
        },
        vat_type_id: {
            value: ((_d = vatTypes === null || vatTypes === void 0 ? void 0 : vatTypes.find((item) => { var _a; return item.id === ((_a = company === null || company === void 0 ? void 0 : company.vat_type) === null || _a === void 0 ? void 0 : _a.id); })) === null || _d === void 0 ? void 0 : _d.id) || null,
            type: "select",
            label: "Применяемый НДС"
        },
        is_our_company: {
            value: !!(company === null || company === void 0 ? void 0 : company.is_our_company) || false,
            type: "checkbox",
            label: "Наше юр. лицо?"
        }
    };
};
export const mapCompanyRequestInformation = (companyBySuggestion, companyType, vatType, isOurCompany) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    return {
        name: (_a = companyBySuggestion.name) === null || _a === void 0 ? void 0 : _a.value,
        req_inn: (_b = companyBySuggestion.req_inn) === null || _b === void 0 ? void 0 : _b.value,
        req_name: (_c = companyBySuggestion.req_name) === null || _c === void 0 ? void 0 : _c.value,
        req_kpp: (_d = companyBySuggestion.req_kpp) === null || _d === void 0 ? void 0 : _d.value,
        req_bank_bik: (_e = companyBySuggestion.req_bank_bik) === null || _e === void 0 ? void 0 : _e.value,
        req_bank_name: (_f = companyBySuggestion.req_bank_name) === null || _f === void 0 ? void 0 : _f.value,
        req_bank_ks: (_g = companyBySuggestion.req_bank_ks) === null || _g === void 0 ? void 0 : _g.value,
        req_bank_ls: (_h = companyBySuggestion.req_bank_ls) === null || _h === void 0 ? void 0 : _h.value,
        req_address: (_j = companyBySuggestion.req_address) === null || _j === void 0 ? void 0 : _j.value,
        req_boss_post: (_k = companyBySuggestion.req_boss_post) === null || _k === void 0 ? void 0 : _k.value,
        req_boss_name: (_l = companyBySuggestion.req_boss_name) === null || _l === void 0 ? void 0 : _l.value,
        planfact_id: ((_m = companyBySuggestion.planfact_id) === null || _m === void 0 ? void 0 : _m.value) || null,
        planfact_account_id: ((_o = companyBySuggestion.planfact_account_id) === null || _o === void 0 ? void 0 : _o.value) || null,
        type_id: companyType,
        vat_type_id: vatType !== '' ? vatType : null,
        is_our_company: isOurCompany
    };
};
export const formatErrorMessage = (message) => {
    if (typeof message === 'object') {
        let result = '';
        const values = Object.values(message);
        values.forEach(item => {
            if (Array.isArray(item)) {
                result += item === null || item === void 0 ? void 0 : item.join('\n');
            }
            else {
                result += item;
                result += '\n';
            }
        });
        return result;
    }
    else {
        return message;
    }
};
