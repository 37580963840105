const vatTypeNullSelectOption = { value: null, label: 'Без НДС' };
function getVatTypeLabel(percentageRate) {
    return !percentageRate ? 'Без НДС' : `НДС ${percentageRate}%`;
}
function getVatTypeSelectOption(vatType) {
    if (!vatType) {
        return vatTypeNullSelectOption;
    }
    return { value: vatType.id, label: getVatTypeLabel(vatType.percentage_rate) };
}
function getVatTypeSelectOptions(vatTypes) {
    if (!vatTypes) {
        return [];
    }
    const mapped = vatTypes.map((vatType) => getVatTypeSelectOption(vatType));
    return [vatTypeNullSelectOption, ...mapped];
}
export { getVatTypeLabel, getVatTypeSelectOption, getVatTypeSelectOptions, vatTypeNullSelectOption, };
