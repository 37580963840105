<script lang="typescript">
  import { onMount } from "svelte";
  import Button, { Label } from "@smui/button";
  import Textfield from "@smui/textfield";
  import Dialog from "./Dialog/index.svelte";
  import { Icon } from "@smui/common";
  import { companiesStore } from "stores/companiesStore";
  import Table from "./Table/index.svelte";

  import { checkINN } from "ru-validation-codes";
  import type { IModal } from "interfaces/Common.interface";
  import {vatTypesStore} from "stores/vatTypesStore";
  const { companyBySuggestion, getCompanies, getCompanyBySuggestion } = companiesStore;
  const { getVatTypes } = vatTypesStore;

  onMount(async () => {
    await Promise.all([getCompanies(), getVatTypes()]);
  });

  let isValidInn = false;

  let label: string = "ИНН контрагента";
  let inn: string = "";
  let editInn: string = "";
  let innInput: HTMLInputElement;
  let selectedCompanyType: number = 1;
  let selectedVatType: number | null = null;
  let isOurCompany: boolean = false;
  let companyModal: IModal | undefined;
  let errors: any = {};
  let isEditMode: boolean = false;
  let editingCompanyId: number | null = null;

  const changeInn = (e: Event) => {
    const value = (e.target as HTMLInputElement).value;
    isValidInn = !checkINN(value);
    if (checkINN(value)) {
      label = "ИНН контрагента";
      inn = value;
    } else {
      label = "Невалидный формат ИНН";
    }
  };

  const parseByInn = () => {
    if (checkINN(inn)) {
      getCompanyBySuggestion(inn);
      companyModal?.open();
    } else {
      innInput.focus();
    }
  };
  const companyModalClose = () => {
    companyModal?.close();
    isEditMode = false;
    errors = {};
    editingCompanyId = null;
    editInn = "";
    $companyBySuggestion = {};
  };
</script>

<div class="companies">
  <div class="companies__inn-form">
    <Textfield
      style="width: 250px; max-height: 38px;"
      bind:value={inn}
      bind:this={innInput}
      {label}
      invalid={isValidInn}
      input$placeholder="Введите ИНН"
      type="text"
      on:input={changeInn}
    />

    <Button variant="unelevated" on:click={parseByInn}>
      <Icon class="material-icons" style={`color:white;font-size: 25px; margin: 0 16px 8px 0`}>
        add
      </Icon>
      <Label>Добавить контрагента</Label>
    </Button>
  </div>

  <div class="companies__table">
    <Table
      bind:isOurCompany
      bind:isEditMode
      bind:editingCompanyId
      {companyModal}
      {companyModalClose}
      bind:selectedCompanyType
      bind:selectedVatType
      bind:inn={editInn}
    />
  </div>

  <Dialog
    bind:errors
    {inn}
    {editInn}
    bind:isOurCompany
    bind:isEditMode
    bind:editingCompanyId
    bind:companyModal
    {companyModalClose}
    bind:selectedCompanyType
    bind:selectedVatType
  />
</div>

<style lang="scss">
  .companies {
    margin-top: 15px;

    &__table {
      margin-top: 15px;
    }

    &__inn-form {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      min-width: 550px;
      max-width: 570px;
    }

    :global(.mdc-dialog__surface) {
      min-width: 700px;
      max-height: 80vh;
    }
  }
</style>
