import { apiGetVatTypes } from "../api/methods/vatTypes/apiGetVatTypes";
import { writable } from "svelte/store";
import { notifier } from "@beyonk/svelte-notifications";
export const vatTypes = writable(null);
const isLoading = writable(false);
const error = writable(null);
const getVatTypes = async () => {
    isLoading.set(true);
    const result = await apiGetVatTypes();
    if (result.success) {
        if (result.data) {
            if (result.success)
                vatTypes.set(result.data);
            else
                error.set(result.message);
        }
    }
    else {
        notifier.danger(result.message, 2000);
    }
    isLoading.set(false);
};
export const vatTypesStore = {
    getVatTypes,
    vatTypes,
    isLoading,
};
